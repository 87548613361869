<template>
  <div v-if="ready">
    <div class="card card-custom gutter-b card-nobody card-sticky">
      <div class="card-header">
        <div class="card-title div-center">
          <h3 class="card-label">Fiche devis #{{devis.id}}</h3>
        </div>
        <div class="card-title div-center">
          <b-button block :variant="DevisStatus.color" size="lg" style="font-size:1.20em">{{DevisStatus.titre.fr}}</b-button>
        </div>
        <div class="card-toolbar div-center">
          <b-button v-b-tooltip.hover :title="$store.back.getText('envoyer_market')" variant="warning" size="sm" class="btn-icon mr-1" @click="sendToMarke" v-if="devis.status_id === 4"><i class="icon-md fas fa-shopping-cart"></i></b-button>
          <b-dropdown v-b-tooltip.hover :title="$store.back.getText('modif_status')" class="mr-1" size="sm" variant="dark" html='<i class="fas fa-thermometer-three-quarters"></i>'>
            <b-dropdown-item v-for="l in params.DevisStatus" :key="l.id" @click="changeStatus(l.id)" :active="l.id === devis.status_id">{{l.titre.fr}}</b-dropdown-item>
          </b-dropdown>
          <b-dropdown v-b-tooltip.hover :title="$store.back.getText('change_user')" class="mr-1" size="sm" variant="primary" html='<i class="fas fa-user"></i>'>
            <b-dropdown-item v-for="u in params.User" :key="u.id" @click="devis.user_id = u.id" :active="u.id === devis.user_id">{{u.prenom}} {{u.nom[0]}}</b-dropdown-item>
          </b-dropdown>
          <b-button v-b-tooltip.hover :title="$store.back.getText('carte_btn')" variant="secondary" size="sm" class="btn-icon mr-1" @click="openMap"><i class="icon-md fas fa-map"></i></b-button>
          <b-button v-b-tooltip.hover :title="$store.back.getText('ajouter_rdv')" variant="info" size="sm" class="btn-icon mr-1" v-b-modal.calendar><i class="icon-md fas fa-calendar"></i></b-button>
          <b-button v-b-tooltip.hover :title="$store.back.getText('editer_devis')" variant="danger" size="sm" class="btn-icon mr-1" @click="save(true)"><i class="icon-md fas fa-paint-brush"></i></b-button>
          <a v-b-tooltip.hover :title="$store.back.getText('save')" class="btn btn-icon btn-sm btn-success mr-1" @click="save()" v-if="$store.getters.currentUser.role === 1">
            <i class="icon-md fas fa-save"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="card card-custom gutter-b mt-3 card-sticky" v-if="map">
      <b-row>
        <b-col md="4">
          <gmap-street-view-panorama  class="pano" :position="waypoints[0]" :zoom="1" style="height:350px"></gmap-street-view-panorama>
        </b-col>
        <b-col md="4">
          <Map :waypoints="waypoints" />
        </b-col>
        <b-col md="4">
          <gmap-street-view-panorama  class="pano" :position="waypoints[1]" :zoom="1" style="height:350px"></gmap-street-view-panorama>
        </b-col>
      </b-row>
    </div>
    <b-tabs pills card>
      <b-tab :title="$store.back.getText('generale')" active class="tab-card-body">
        <div class="card-deck">
          <div class="card card-custom gutter-b">
            <div class="card-header">
              <div class="card-title">
                <h3 class="card-label">{{$store.back.getText('info-client')}}</h3>
              </div>
            </div>
            <div class="card-body">
              <b-row>
                <b-col md="4">
                  <b-form-group :label="this.$store.back.getText('last_name')+':'">
                    <b-form-input v-model="devis.nom" required></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group :label="this.$store.back.getText('first_name')+':'">
                    <b-form-input v-model="devis.prenom" required></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group :label="this.$store.back.getText('phone')+':'">
                    <b-form-input v-model="devis.telephone" required></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group :label="this.$store.back.getText('mobile')+':'">
                    <b-form-input v-model="devis.mobile" required></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group :label="this.$store.back.getText('email')+':'">
                    <b-form-input v-model="devis.email" required></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group :label="this.$store.back.getText('type')+':'">
                    <select class="form-control"  v-model="devis.type_id">
                      <option v-for="(t,i) in params.LeadType" :key="i" :value="t.id">{{t.titre.fr}}</option>
                    </select>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="card card-custom gutter-b">
            <div class="card-header">
              <div class="card-title">
                <h3 class="card-label">{{$store.back.getText('prestation')}}</h3>
              </div>
            </div>
            <div class="card-body">
              <b-row>
                <b-col md="4">
                  <b-form-group :label="this.$store.back.getText('distance')+' km:'">
                    <b-form-input v-model.number="devis.distance" readonly></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group :label="this.$store.back.getText('volume')+' m3:'">
                    <b-form-input v-model="devis.volume" @focusout="setVolume()" required></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group :label="this.$store.back.getText('categorie')+':'">
                    <select class="form-control" v-model="devis.categorie_id">
                      <option v-for="(t,i) in params.Categorie" :value="t.id" :key="i">{{t.titre.fr}}</option>
                    </select>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group :label="this.$store.back.getText('type_livraison')+':'">
                    <select class="form-control" v-model="devis.livraison_id">
                      <option v-for="(t,i) in params.Livraison" :value="t.id" :key="i">{{t.titre.fr}}</option>
                    </select>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group :label="this.$store.back.getText('superficie')+' m2:'">
                    <b-form-input v-model.number="devis.surface" required></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab :title="$store.back.getText('chargement')+'/'+$store.back.getText('livraison')" class="tab-card-body">
        <div class="card-deck mt-3">
          <div class="card card-custom gutter-b">
            <div class="card-header">
              <div class="card-title">
                <h3 class="card-label">{{$store.back.getText('info')}} {{$store.back.getText('chargement')}}</h3>
              </div>
            </div>
            <div class="card-body">
              <b-row>
                <b-col md="6">
                  <b-form-group :label="this.$store.back.getText('date')+':'">
                    <b-form-datepicker v-model="devis.date_chargement"></b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group :label="this.$store.back.getText('type_bien')+':'">
                    <select class="form-control" v-model.number="devis.depart_options.bien_id">
                      <option v-for="(b,i) in params.Bien" :value="b.id" :key="i">{{b.titre.fr}}</option>
                    </select>
                  </b-form-group>
                </b-col>
              </b-row>
              <AutoComplete v-model="devis" type="depart"/>
              <b-row>
                <b-col md="6">
                  <b-form-group :label="this.$store.back.getText('portage')+':'">
                    <b-form-input v-model.number="devis.depart_options.portage" type="text" required :placeholder="this.$store.back.getText('portage')"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group :label="this.$store.back.getText('etage')+':'">
                    <b-form-input v-model.number="devis.depart_options.etage" type="text" required :placeholder="this.$store.back.getText('etage')"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-checkbox size="lg" id="depart_passage_fenetre" v-model="devis.depart_options.passage_fenetre">
                    {{$store.back.getText('passage_fenetre')}}
                  </b-form-checkbox>
                </b-col>
                <b-col md="4">
                  <b-form-checkbox size="lg" id="depart_ascenseur" v-model="devis.depart_options.ascenseur">
                    {{$store.back.getText('ascenseur')}}
                  </b-form-checkbox>
                </b-col>
                <b-col md="4">
                  <b-form-checkbox size="lg" id="depart_ast" v-model="devis.depart_options.ast">
                    {{$store.back.getText('ast')}}
                  </b-form-checkbox>
                </b-col>
                <b-col md="4">
                  <b-form-checkbox size="lg" id="depart_monte_meuble" v-model="devis.depart_options.monte_meuble">
                    {{$store.back.getText('monte_meuble')}}
                  </b-form-checkbox>
                </b-col>
                <b-col md="4">
                  <b-form-checkbox size="lg" id="depart_cave" v-model="devis.depart_options.cave">
                    {{$store.back.getText('cave')}}
                  </b-form-checkbox>
                </b-col>
                <b-col md="4">
                  <b-form-checkbox size="lg" id="depart_jardin" v-model="devis.depart_options.jardin">
                    {{$store.back.getText('jardin')}}
                  </b-form-checkbox>
                </b-col>
                <b-col md="4">
                  <b-form-checkbox size="lg" id="depart_garage" v-model="devis.depart_options.garage">
                    {{$store.back.getText('garage')}}
                  </b-form-checkbox>
                </b-col>
                <b-col md="4">
                  <b-form-checkbox size="lg" id="depart_grenier" v-model="devis.depart_options.grenier">
                    {{$store.back.getText('grenier')}}
                  </b-form-checkbox>
                </b-col>
                <b-col md="4">
                  <b-form-checkbox size="lg" id="depart_transbordement" v-model="devis.depart_options.transbordement">
                    {{$store.back.getText('transbordement')}}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="card card-custom gutter-b">
            <div class="card-header">
              <div class="card-title">
                <h3 class="card-label">{{$store.back.getText('info')}} {{$store.back.getText('livraison')}}</h3>
              </div>
            </div>
            <div class="card-body">
              <b-row>
                <b-col md="6">
                  <b-form-group :label="this.$store.back.getText('date')+':'">
                    <b-form-datepicker v-model="devis.date_livraison"></b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group :label="this.$store.back.getText('type_bien')+':'">
                    <select class="form-control" v-model.number="devis.destination_options.bien_id">
                      <option v-for="(b,i) in params.Bien" :value="b.id" :key="i">{{b.titre.fr}}</option>
                    </select>
                  </b-form-group>
                </b-col>
              </b-row>
              <AutoComplete v-model="devis" type="destination"/>
              <b-row>
                <b-col md="6">
                  <b-form-group :label="this.$store.back.getText('portage')+':'">
                    <b-form-input v-model.number="devis.destination_options.portage" type="text" required :placeholder="this.$store.back.getText('portage')"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group :label="this.$store.back.getText('etage')+':'">
                    <b-form-input v-model.number="devis.destination_options.etage" type="text" required :placeholder="this.$store.back.getText('etage')"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-checkbox size="lg" id="destination_passage_fenetre" v-model="devis.destination_options.passage_fenetre">
                    {{$store.back.getText('passage_fenetre')}}
                  </b-form-checkbox>
                </b-col>
                <b-col md="4">
                  <b-form-checkbox size="lg" id="destination_ascenseur" v-model="devis.destination_options.ascenseur">
                    {{$store.back.getText('ascenseur')}}
                  </b-form-checkbox>
                </b-col>
                <b-col md="4">
                  <b-form-checkbox size="lg" id="destination_ast" v-model="devis.destination_options.ast">
                    {{$store.back.getText('ast')}}
                  </b-form-checkbox>
                </b-col>
                <b-col md="4">
                  <b-form-checkbox size="lg" id="destination_monte_meuble" v-model="devis.destination_options.monte_meuble">
                    {{$store.back.getText('monte_meuble')}}
                  </b-form-checkbox>
                </b-col>
                <b-col md="4">
                  <b-form-checkbox size="lg" id="destination_cave" v-model="devis.destination_options.cave">
                    {{$store.back.getText('cave')}}
                  </b-form-checkbox>
                </b-col>
                <b-col md="4">
                  <b-form-checkbox size="lg" id="destination_jardin" v-model="devis.destination_options.jardin">
                    {{$store.back.getText('jardin')}}
                  </b-form-checkbox>
                </b-col>
                <b-col md="4">
                  <b-form-checkbox size="lg" id="destination_garage" v-model="devis.destination_options.garage">
                    {{$store.back.getText('garage')}}
                  </b-form-checkbox>
                </b-col>
                <b-col md="4">
                  <b-form-checkbox size="lg" id="destination_grenier" v-model="devis.destination_options.grenier">
                    {{$store.back.getText('grenier')}}
                  </b-form-checkbox>
                </b-col>
                <b-col md="4">
                  <b-form-checkbox size="lg" id="destination_transbordement" v-model="devis.destination_options.transbordement">
                    {{$store.back.getText('transbordement')}}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab :title="$store.back.getText('inventaire')" class="tab-card-body">
        <b-row>
          <b-col md="4" offset-md="4">
            <b-form-input v-model="item_filter" type="text" required placeholder="Entre votre objet"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8">
            <b-row class="mt-3">
              <b-col md="3" cols="6" class="mt-2" v-for="(item,i) in itemFiltered" :key="i">
                <div class="card text-center border-primary">
                  <div class="card-header bg-primary text-white" style="padding: 0.5rem 0.25rem;">{{item.titre.fr}}</div>
                  <img :src="item.icon" alt="Card image" class="card-img-top mt-1" style=" max-height: 110px; ">
                  <div class="card-body" style="padding:1.5rem;">
                    <h6 class="card-subtitle text-muted mb-2">{{item.volume}}&#13221;</h6>
                    <b-card-text>
                      <b-button v-b-tooltip.hover :title="$store.back.getText('ajouter_inventaire')" size="sm" variant="success" @click="addItem(item)" v-if="devis.inventaire.find(t => t.ref === item.id) === undefined"><i class="fas fa-plus"></i></b-button>
                    </b-card-text>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="4">
            <div class="card card-custom gutter-b mt-6">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">{{$store.back.getText('objet_select')}}</h3>
                </div>
              </div>
              <div class="card-body">
                <b-row v-for="(item,i) in devis.inventaire" :key="i" class="mt-1">
                  <b-col cols="6">
                    <span>{{item.titre}}</span>
                  </b-col>
                  <b-col cols="2">
                    <b-button v-b-tooltip.hover :title="$store.back.getText('moin_quantier')" size="sm" class="btn-block" variant="danger" @click="removeInventaireQuantite(item)"><i class="fas fa-minus"></i></b-button>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input v-model="item.quantite" readonly></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <b-button v-b-tooltip.hover :title="$store.back.getText('ajouter_quantier')" size="sm" class="btn-block" variant="success" @click="addInventaireQuantite(item)"><i class="fas fa-plus"></i></b-button>
                  </b-col>
                </b-row>
              </div>
              <div class="card-footer">
                {{$store.back.getText('volume_total')}}: {{volumeTotal}}&#13221;
              </div>
            </div>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="$store.back.getText('finance')" class="tab-card-body">
        <div class="card card-custom gutter-b mt-3">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{$store.back.getText('detail-financier')}}</h3>
            </div>
            <div class="card-toolbar">
              <b-button v-b-tooltip.hover :title="$store.back.getText('ajouter_ligne')" variant="success" size="sm" @click="addQuote()" class="btn-icon mr-1"><i class="icon-md fas fa-plus"></i></b-button>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">{{$store.back.getText("titre")}}</th>
                  <th scope="col">{{$store.back.getText("quantite")}}</th>
                  <th scope="col">{{$store.back.getText("prix_ht")}}</th>
                  <th scope="col">{{$store.back.getText("tva")}}</th>
                  <th scope="col">{{$store.back.getText("action")}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(q,i) in devis.quotes" :key="i">
                  <td scope="row">
                    <b-form-input v-model="q.titre"></b-form-input>
                  </td>
                  <td scope="row">
                    <b-form-input v-model.number="q.quantite"></b-form-input>
                  </td>
                  <td scope="row">
                    <b-form-input v-model.number="q.prix"></b-form-input>
                  </td>
                  <td scope="row">
                    <b-form-input v-model.number="q.tva"></b-form-input>
                  </td>
                  <td scope="row">
                    <b-button v-b-tooltip.hover :title="$store.back.getText('retirer_ligne')" variant="danger" size="sm" class="btn-icon mr-1" @click="removeQuote(i)"><i class="icon-md fas fa-minus"></i></b-button>
                  </td>
                </tr>
              </tbody>
            </table>
            <b-row>
              <b-col md="3">
                <b-form-group :label="this.$store.back.getText('total-ht')+':'">
                  <b-form-input v-model="devis.total_ht" type="text" readonly></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group :label="this.$store.back.getText('tva')+':'">
                  <b-form-input v-model="devis.total_tva" readonly></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group :label="this.$store.back.getText('total-ttc')+':'">
                  <b-form-input v-model="devis.total_ttc"  type="text" readonly></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group :label="this.$store.back.getText('solde')+':'">
                  <b-form-input :value="devis.total_ttc - devis.total_acompte"  type="text" readonly></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="card card-custom gutter-b mt-3">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{$store.back.getText('acompte-recu')}}</h3>
            </div>
            <div class="card-toolbar">
              <b-button v-b-tooltip.hover :title="$store.back.getText('ajouter_ligne')"  variant="success" size="sm" @click="addAcomptes" class="btn-icon mr-1"><i class="icon-md fas fa-plus"></i></b-button>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">{{$store.back.getText("mode_paiement")}}</th>
                  <th scope="col">{{$store.back.getText("commentaire")}}</th>
                  <th scope="col">{{$store.back.getText("montant")}}</th>
                  <th scope="col">{{$store.back.getText("date")}}</th>
                  <th scope="col">{{$store.back.getText("action")}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(a,i) in devis.acomptes" :key="i">
                  <td scope="row">
                    <select class="form-control" v-model.number="a.acompte_type">
                      <option v-for="(t,it) in params.AcompteType" :key="it" :value="t.id">{{t.titre.fr}}</option>
                    </select>
                  </td>
                  <td scope="row">
                    <b-form-input v-model="a.commentaire" required></b-form-input>
                  </td>
                  <td scope="row">
                    <b-form-input v-model.number="a.montant" required></b-form-input>
                  </td>
                  <td scope="row">
                    <b-form-datepicker v-model="a.date"></b-form-datepicker>
                  </td>
                  <td scope="row">
                    <b-button v-b-tooltip.hover :title="$store.back.getText('retirer_ligne')"  variant="danger" size="sm" class="btn-icon mr-1" @click="removeAcomptes(i)"><i class="icon-md fas fa-minus"></i></b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-tab>
      <b-tab :title="$store.back.getText('documents')" class="tab-card-body">
        <div class="card card-custom gutter-b mt-3">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{$store.back.getText('liste-fichier')}}</h3>
            </div>
            <div class="card-toolbar">
              <b-button v-b-tooltip.hover :title="$store.back.getText('ajouter_doc')" variant="success" size="sm" class="btn-icon mr-1" v-b-modal.import><i class="icon-md fas fa-plus"></i></b-button>
            </div>
          </div>
          <div class="card-body">
            <div>
              <b-row>
                <b-button size="md" variant="success" @click="docs_filter = null" class="ml-5 mr-1">Tout</b-button>
                <b-button size="md" variant="primary" class="mr-1" v-for="(t,i) in params.GEDType"  @click="docs_filter = t.id"  :key="i">{{t.titre.fr}}</b-button>
              </b-row>
              <b-row class="mt-2">
                <b-col md="2" v-for="(d,i) in docsFiltered" :key="i">
                  <div class="card text-center border-primary">
                    <div class="card-header bg-primary text-white" style="padding: 1rem 1.25rem;">{{getFileName(d)}}</div>
                    <div class="card-body" style="padding:1.5rem;">
                      <h6 class="card-subtitle text-muted mb-2">{{$store.back.getParamTitre(params.GEDType,d.ged_type)}}</h6>
                      <h6 class="card-subtitle text-muted mb-2 mt-1">{{$store.back.timestampToDate(d.date_create,false)}}</h6>
                      <b-card-text>
                        <b-button-group>
                          <b-button v-b-tooltip.hover :title="$store.back.getText('ouvrir_doc')" size="sm" variant="primary" :href="d.url" target="_blank"><i class="fas fa-eye"></i></b-button>
                          <b-button v-b-tooltip.hover :title="$store.back.getText('retirer_doc')" size="sm" variant="danger" @click="removeFile(i)"><i class="fas fa-minus"></i></b-button>
                        </b-button-group>
                      </b-card-text>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <div class="card-deck">
      <div class="card card-custom gutter-b">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Commentaire</h3>
          </div>
        </div>
        <div class="card-body">
          <b-form-group :label="this.$store.back.getText('commentaire')+':'" >
            <b-form-textarea v-model="devis.commentaire" rows="4" readonly></b-form-textarea>
          </b-form-group>
          <b-form-group :label="this.$store.back.getText('ajouter')+':'">
            <b-input-group>
              <b-form-input v-model="commentaireTmp" type="text" required placeholder="Entre votre commentaire"></b-form-input>
              <template #append>
                <b-button v-b-tooltip.hover :title="$store.back.getText('ajouter_commentaire')" variant="success" class="btn-icon mr-1" @click="addCommentaire()"><i class="icon-md fas fa-plus"></i></b-button>
              </template>
            </b-input-group>
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="row card-price">
      <div class="col-md-3 offset-md-9">
        <div class="card text-center border-primary">
          <div class="card-body">
            <p class="card-text">{{$store.back.getText('du')}} {{devis.depart.cp}} {{$store.back.getText('au')}} {{devis.destination.cp}} ({{devis.distance}}km)</p>
            <p class="card-text">{{devis.volume}}m3 {{$store.back.getText('en')}} {{$store.back.getParamTitre(params.Categorie,devis.categorie_id)}}</p>
            <button type="button" class="btn btn-success btn-lg btn-block" style="font-size: 20px;font-weight: bold;">TTC {{devis.total_ttc}}{{params.Pays[0].symbol}}</button>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="calendar" title="Calendrier" ref="calendar">
      <b-form>
        <b-form-group label="Titre:">
          <b-form-input v-model="calendarTmp.name" type="text" required placeholder="Entre le titre"></b-form-input>
        </b-form-group>
        <b-form-group label="Detail:">
          <b-form-input v-model="calendarTmp.detail" type="text" required placeholder="Entre le deail"></b-form-input>
        </b-form-group>
        <b-form-group label="Debut:">
          <b-row>
            <b-col size="md-6">
              <b-form-datepicker v-model="calDateStart"></b-form-datepicker>
            </b-col>
            <b-col size="md-6">
              <b-form-timepicker v-model="calTimeStart"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group label="Fin:">
          <b-row>
            <b-col size="md-6">
              <b-form-datepicker v-model="calDateStart" readonly></b-form-datepicker>
            </b-col>
            <b-col size="md-6">
              <b-form-timepicker v-model="calTimeEnd"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right mr-2" @click="saveCal()">
            Sauvegarder
          </b-button>
          <b-button variant="danger" class="float-right mr-2" @click="$refs.calendar.hide();">
            Fermer
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="import" title="Importation de document" ref="import">
      <b-form>
        <b-form-group label="Type:">
          <select class="form-control" v-model.number="tmpDoc.ged_type">
            <option v-for="(t,i) in params.GEDType" :key="i" :value="t.id">{{t.titre.fr}}</option>
          </select>
        </b-form-group>
        <b-form-group label="Dociment:">
          <b-form-file  @change="handleFile" placeholder="Selectionnez votre fichier a importer"></b-form-file>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button v-if="loaderImportFile === false" variant="primary" class="float-right" @click="addDoc()">
            Valide
          </b-button>
          <b-button v-else variant="info" size="sm" class="btn-icon mr-1 float-right"> <i class="fas fa-circle-notch fa-spin"></i></b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Map from '@/components/Map'
import AutoComplete from '@/components/AutoComplete';

export default {
  data(){
    return {
      ready:false,
      map:false,
      item_filter:null,
      calDateStart:null,
      calTimeStart:"12:00",
      calTimeEnd:"12:30",
      calendarTmp:{
        name: null,
        start: null,
        end: null,
        detail: null,
        url:`/${location.hash}`
      },
      commentaireTmp:null,
      devis: this.$store.back.getSchema('devis'),
      tmpDoc:{
        "url": null,
        "ged_type": 3,
        "date_create": null,
        "status": 1
      },
      loaderImportFile:false,
      docs_filter:null,
      params:{
        LeadType:[],
        DevisStatus:[],
        status:[],
        Bien:[],
        Livraison:[],
        Categorie:[],
        Prestation:[],
        InventaireItem:[],
        GEDType:[],
        Pays:[],
        User:[]
      }
    };
  },
  components: {AutoComplete, Map},
  watch: {
    calDateStart(){
      this.calendarTmp.start = this.calDateStart+' '+this.calTimeStart;
      this.calendarTmp.end = this.calDateStart+' '+this.calTimeEnd;
    },
    'devis.depart.lon'(){
      this.getDistance();
    },
    'devis.destination.lon'(){
      this.getDistance();
    },
    'devis.quotes':{
      deep:true,
      handler(){
        this.initPrice()
      }
    },
    'devis.acomptes':{
      deep:true,
      handler(){
        var total = 0;
        this.devis.acomptes.forEach((item) => {
          total += item.montant;
        });
        this.devis.total_acompte = total;
      }
    }
  },
  methods: {
    save(edit = false) {
      this.$store.back.ajax('/devis/update', this.devis, (res) => {
        if(res.status){
          this.devis = res.data;
          this.$bvToast.toast(`Devis enregistre !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
          if(edit === true){
            this.$router.push({path:'/devis/edit/'+this.devis.id});
          }
        }
      });
    },
    saveCal(){
      this.$store.back.ajax('/calendrier/create', this.calendarTmp, (res) => {
        if(res.status === true){
          this.$bvToast.toast(`Votre rdv a ete sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
          this.$refs.calendar.hide();
        }
      });
    },
    addCommentaire(){
      if(this.devis.commentaire === null){
        this.devis.commentaire = "";
      }
      var user = this.$store.back.getUser();
      var date = new Date();
      this.devis.commentaire += `${this.commentaireTmp} | ${user.prenom} ${user.nom} ${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`+"\n";
      this.commentaireTmp = null;
    },
    changeStatus(id){
      if(this.devis.status_id === id){
        return false;
      }
      this.devis.status_id = id;
    },
    openMap(){
      this.map = !this.map;
      if(this.map === false){
        return false;
      }
      if(this.waypoints === false){
        this.map = false;
      }
    },
    getDistance(){
      if(this.devis.depart.lon === null || this.devis.depart.lat === null || this.devis.destination.lon === null || this.devis.destination.lat === null){
        return false;
      }
      var url = `http://maps.get-move.pro/routing/route/driving/${this.devis.depart.lon},${this.devis.depart.lat};${this.devis.destination.lon},${this.devis.destination.lat}?overview=false&alternatives=true&steps=true&hints=;`;
      fetch(url).then( response => {
        response.json().then( data =>{
          if(data.routes[0].distance != undefined){
            this.devis.distance = Math.floor(data.routes[0].distance/1000);
          }
        })
      });
    },
    addItem(row){
      var item = {
        "titre":row.titre.fr,
        "volume":row.volume,
        "quantite":1,
        "ref":row.id,
        "icon":row.icon
      }
      this.devis.inventaire.push(item);
      this.devis.volume = this.volumeTotal;
    },
    addInventaireQuantite(item){
      item.quantite += 1;
      this.devis.volume = this.volumeTotal;
    },
    removeInventaireQuantite(item){
      if(item.quantite === 1){
        this.devis.inventaire = this.devis.inventaire.filter(i => i.ref != item.ref)
      }
      item.quantite -= 1;
      this.devis.volume = this.volumeTotal;
    },
    getFileName(obj){
      var q = obj.url.slice(obj.url.indexOf("?")+1);
      var params = new URLSearchParams(q);
      var filename = params.get('q');
      filename = filename.slice(0,19-3)
      return filename+"...";
    },
    removeFile(index){
      this.devis.docs.splice(index,1);
    },
    handleFile(e){
      var the = this;
      this.loaderImportFile = true;
      var files = e.target.files;
      files.forEach((item) => {
        the.upladFile(item);
      });
    },
    upladFile(file){
      const fileUploaded = file;
      const reader = new FileReader();
      reader.onload = (e) => {
        var tmp = {
          'file': e.target.result,
          'file_name': fileUploaded.name
        }
        this.$store.back.ajax('/ged/import', tmp, (res) => {
          if(res.status == true){
            this.tmpDoc.url = res.data;
            this.tmpDoc.date_create = Math.floor(Date.now() / 1000);
            this.loaderImportFile = false;
          }
        })
      }
      reader.readAsDataURL(fileUploaded);
    },
    addDoc(){
      var tmp = JSON.stringify(this.tmpDoc);
      tmp = JSON.parse(tmp)
      this.devis.docs.push(tmp);
      this.tmpDoc.url = null;
      this.tmpDoc.ged_type = 3;
      this.tmpDoc.date_create = null;
      this.$refs.import.hide();
    },
    setVolume(){
      if(this.devis.volume === "" || this.devis.volume === null){
        this.devis.volume = this.volumeTotal;
      }
      this.devis.volume =  parseFloat(this.devis.volume);
      if(this.devis.volume < this.volumeTotal){
        this.$bvToast.toast(this.$store.back.getText('error_volume'), {
          title: 'Erreur !',
          variant: 'danger',
          solid: true
        });
      }else if(this.devis.volume > this.volumeTotal){
        var check = this.params.InventaireItem.find(i => i.id === 131)
        if(check === undefined){
          return false;
        }
        var item = {
          "titre":check.titre.fr,
          "volume": this.devis.volume - this.volumeTotal,
          "quantite":1,
          "ref":check.id,
          "icon":check.icon
        }
        this.devis.inventaire.push(item);
      }
      this.devis.volume = this.volumeTotal;
    },
    addQuote(){
      this.devis.quotes.push({
        "titre": null,
        "quantite": 1,
        "prix": 0,
        "tva": this.params.Pays[0].tva,
      });
    },
    removeQuote(index){
      this.devis.quotes.splice(index,1);
    },
    initPrice(){
      var ttc = 0;
      var ht = 0;
      var tva = 0;
      this.devis.quotes.forEach((item) => {
        var ht_tmp = item.prix*item.quantite;
        var tva_tmp = ht_tmp * (item.tva/100);
        ht += ht_tmp;
        tva += tva_tmp;
        ttc += ht_tmp+tva_tmp
      });
      this.devis.total_ht = ht;
      this.devis.total_ttc = ttc;
      this.devis.total_tva = tva;
    },
    removeAcomptes(index){
      this.devis.acomptes.splice(index,1);
    },
    addAcomptes(){
      this.devis.acomptes.push({
        "acompte_type": null,
        "montant": null,
        "date": null,
        "commentaire":null
      });
    },
    sendToMarke(){
      setTimeout(() => {
        this.$bvToast.toast(this.$store.back.getText('contrat_market'), {
          title: 'Sauvegarder !',
          variant: 'success',
          solid: true
        },2000);
      })
    }
  },
  computed: {
    waypoints(){
      if(this.devis.depart.lon === null || this.devis.depart.lon === null){
        this.$bvToast.toast(this.$store.back.getText('merci_renseigner'), {
          title: 'Erreur !',
          variant: 'danger',
          solid: true
        });
        return false
      }
      return [
        { lat: this.devis.depart.lat, lng: this.devis.depart.lon },
        { lat: this.devis.destination.lat, lng: this.devis.destination.lon }
      ];
    },
    DevisStatus(){
      var check = this.params.DevisStatus.find(s => s.id == this.devis.status_id);
      if(check === undefined){
        check = {
          id:1,
          titre:{
            fr:"Disponible"
          },
          color:"primary"
        }
      }
      return check;
    },
    itemFiltered(){
      if(this.item_filter === null || this.item_filter === ""){
        return this.params.InventaireItem;
      }
      var str = this.item_filter.toLocaleLowerCase();
      return this.params.InventaireItem.filter(f => f.labelle.indexOf(str) > -1)
    },
    volumeTotal(){
      var total = 0;
      this.devis.inventaire.forEach((item) => {
        total += item.quantite * item.volume
      });
      return total.toFixed(2);
    },
    docsFiltered(){
      if(this.docs_filter === null){
        return this.devis.docs;
      }else{
        return this.devis.docs.filter(d => d.ged_type === this.docs_filter);
      }
    },
  },
  beforeMount(){
    if(this.$route.params.id != undefined){
      this.$store.back.ajax(`/devis/${this.$route.params.id}`, null, (res) => {
        if(res.status){
          this.devis = res.data;
        }
      });
    }
    let params = {
      modules: ["LeadType","DevisStatus","Bien","Livraison","Categorie","Prestation","InventaireItem","GEDType","Pays","AcompteType"]
    };
    this.$store.back.ajax('/magic/all', params, (res) => {
      if(res.status === true){
        this.params.LeadType = res.data['LeadType'];
        this.params.DevisStatus = res.data['DevisStatus'];
        this.params.status = res.data['status'];
        this.params.Bien = res.data['Bien'];
        this.params.Livraison = res.data['Livraison'];
        this.params.Categorie = res.data['Categorie'];
        this.params.Pays = res.data['Pays'];
        this.params.Prestation = res.data['Prestation'];
        this.params.AcompteType = res.data['AcompteType'];
        res.data['InventaireItem'].forEach((item) => {
          item.labelle = item.titre.fr.toLocaleLowerCase();
        });
        this.params.InventaireItem = res.data['InventaireItem'];
        this.params.GEDType = res.data['GEDType'];
        this.params.User = res.data['MyUsers'].users;
        this.ready = true;
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
  }
};
</script>
