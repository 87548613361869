<template>
  <b-row v-if="ready">
    <b-col md="12">
      <b-form-group :label="this.$store.back.getText('recherche_auto')+':'">
        <b-form-input v-model="adresseSaissie" @keyup="adresseAuto(adresseSaissie)" required></b-form-input>
      </b-form-group>
    </b-col>
    <b-col md="12 mb-3" style="margin-top: -23px;">
      <b-list-group>
        <b-list-group-item button v-for="(r,i) in result" :key="i" @click="setAdresse(r)">{{r.properties.label}}</b-list-group-item>
      </b-list-group>
    </b-col>
    <b-col md="6">
      <b-form-group :label="this.$store.back.getText('adresse')+':'">
        <b-form-input v-model="obj[type].adresse"></b-form-input>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group :label="this.$store.back.getText('ville')+':'">
        <b-form-input v-model="obj[type].ville" disabled required></b-form-input>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group :label="this.$store.back.getText('cp')+':'">
        <b-form-input v-model="obj[type].cp" disabled required></b-form-input>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group :label="this.$store.back.getText('pays')+':'">
        <b-form-input v-model="obj[type].pays" disabled required></b-form-input>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: "AutoComplete",
  model: {
    prop: 'obj',
  },
  props: {
    obj: Object,
    type: String
  },
  data(){
    return {
      ready:false,
      adresseSaissie: null,
      result:[]
    }
  },
  methods: {
    adresseAuto(data){
      var url = "https://maps.get-move.pro/geocoding/fr/position?q="+encodeURI(data);
      fetch(url).then( response => {
        response.json().then( data =>{
          this.result = data.features
        })
      }).catch( () => {
        this.result = [];
      });
    },
    setAdresse(data){
      if(data.length < 5){
        this.result = [];
        return false
      }
      var type = this.type
      var adresse = '';
      adresse += data.properties.housenumber != undefined ? data.properties.housenumber+' ': '';
      adresse += data.properties.street != undefined ? data.properties.street : '';
      this.obj[type].adresse = adresse;
      this.obj[type].cp = data.properties.postcode != undefined ? data.properties.postcode : null;
      this.obj[type].ville = data.properties.city != undefined ? data.properties.city : null;
      this.obj[type].pays = 'France';
      this.obj[type].lon = data.geometry.coordinates[0];
      this.obj[type].lat = data.geometry.coordinates[1];
      this.result = [];
    }
  },
  mounted() {
    this.ready = true;
  }
};
</script>
